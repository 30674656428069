import api from '@/utils/api';
import registerServer from '@/utils/registerServer';
import request from '@/utils/request';

const {
    getDialog,
    setDialog,
    listDialog,
    removeDialog,
    getConversation,
    setConversation,
    completeConversation,
    listConversation,
    removeConversation,
    createToken,
    listToken,
    removeToken,
    getStats,
    createExternalConversation,
    createExternalCompletionEvaluate,
    listExternalConversationByUserId,
    listExternalCompletionEvaluate,
    externalCompletionEvaluateOptimization,
    getExternalConversation,
    completeExternalConversation,
} = api;

const methods = {
    getDialog: {
        url: getDialog,
        method: 'get',
    },
    setDialog: {
        url: setDialog,
        method: 'post',
    },
    removeDialog: {
        url: removeDialog,
        method: 'post',
    },
    listDialog: {
        url: listDialog,
        method: 'get',
    },
    listConversation: {
        url: listConversation,
        method: 'get',
    },
    getConversation: {
        url: getConversation,
        method: 'get',
    },
    setConversation: {
        url: setConversation,
        method: 'post',
    },
    completeConversation: {
        url: completeConversation,
        method: 'post',
    },
    removeConversation: {
        url: removeConversation,
        method: 'post',
    },
    createToken: {
        url: createToken,
        method: 'post',
    },
    listToken: {
        url: listToken,
        method: 'get',
    },
    removeToken: {
        url: removeToken,
        method: 'post',
    },
    getStats: {
        url: getStats,
        method: 'get',
    },
    createExternalConversation: {
        url: createExternalConversation,
        method: 'get',
    },

    createExternalCompletionEvaluate: {
        url: createExternalCompletionEvaluate,
        method: 'post',
    },
    listExternalConversationByUserId: {
        url: listExternalConversationByUserId,
        method: 'post',
    },

    listExternalCompletionEvaluate: {
        url: listExternalCompletionEvaluate,
        method: 'post',
    },

    externalCompletionEvaluateOptimization: {
        url: externalCompletionEvaluateOptimization,
        method: 'post',
    },

    getExternalConversation: {
        url: getExternalConversation,
        method: 'get',
    },
    completeExternalConversation: {
        url: completeExternalConversation,
        method: 'post',
    },
} as const;

const chatService = registerServer<keyof typeof methods>(methods, request);

export default chatService;
